


.style-guide_header {
    text-align: center;
    margin-bottom: 4rem;
}

.style-guide_box {
    margin-bottom: 2rem;
    background: #fff;
    border-radius: 10px;
    padding: 2rem;
}

.style-guide_box h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.code-info {
    font-size: 11px;
    color: #999;
}



.style-guide_box_icons .col {
    text-align: center;
}

.style-guide_box_icons .col i {
    display: block;
    font-size: 4rem;
    color: #b5b5b5;
    margin-bottom: 10px;
}






.style-guide_box_type h1,
.style-guide_box_type h2,
.style-guide_box_type h3,
.style-guide_box_type h4 {
    line-height: 1;
    margin-bottom: 0;
}

.style-guide_box_type span.code-info {
    margin-bottom: 12px;
    display: block;
}





.style-guide_box_buttons button {
    display: block;
    margin: 0 auto;
    margin-bottom: 4px;
}

.style-guide_box_buttons div div div {
    display: inline-block;
    margin-right: 20px;
    text-align: center;
}



.style-guide_box_colors .col {
   padding: 150px 12px 12px 12px;
   border-radius: 10px;
}

.style-guide_box_colors .col span.code-info {
    color: #fff;
}




.style-guide_box_grid .col {
    background: #b5b5b5;
    padding: 10px 10px;
}

.style-guide_box_grid .code-info {
    color: #fff;
}





.directory-header {
    background: #469AD4;
    color: #fff;
    padding: 10px 15px;
    margin-bottom: 6px;
}

.directory-links a {
    display: block;
    margin-top: 4px;
    padding-left: 15px;
    color: #999;
}

